import React from 'react';
import { useNavigate } from 'react-router-dom';
import carousel2 from '../images/Haleon/carouselCountdown.png';
import '../css/Home.css';

const CountdownCarousel = ({timeLeft}) => {
	const navigate = useNavigate();

	const formatTime = (time) => {
		return `${time < 10 ? '0' : ''}${time}`;
	};

	return (
		<div className="carousel-container">
			<div
				onClick={() => navigate('/badges')}
				style={{ position: 'relative' }}
			>
				<img
					src={carousel2}
					alt=""
					className="w-100"
					style={{ objectFit: 'contain' }}
				/>

				{/* countdown timer */}
				<div
					style={{
						position: 'absolute',
						top: '5%',
						right: '4%',
						width: '52%',
					}}
				>
					<div className="countdown-container">
						<div>
							<div className="countdown-time">
								{formatTime(timeLeft.days)}
							</div>
							<div className="countdown-label">Day</div>
						</div>
						<div>
							<div className="countdown-time">
								{formatTime(timeLeft.hours)}
							</div>
							<div className="countdown-label">Hr</div>
						</div>
						<div>
							<div className="countdown-time">
								{formatTime(timeLeft.minutes)}
							</div>
							<div className="countdown-label">Min</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CountdownCarousel;
