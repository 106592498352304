import React, { useState } from 'react';
import { Carousel } from 'antd';
import { useNavigate } from 'react-router-dom';
import carousel1 from '../images/Haleon/carousel1_my.png';
import carousel2 from '../images/Haleon/carousel2_my.png';
import CountdownCarousel from './CountdownCarousel';

const My_carousel = ({timeLeft, hideBanner}) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const navigate = useNavigate();
	const images = [
		{ img: carousel1, pageName: '/upload' },
		{ img: carousel2, pageName: '/upload' },
	];

	const handleImageClick = (pageName) => {
		if (pageName) {
			navigate(pageName);
		}
	};

	const onChange = (current) => {
		setCurrentSlide(current);
	};
	
	return (
		<div>
				<Carousel
					dots={true}
					afterChange={onChange}
					className="sg-carousel-container"
					dotPosition='bottom'
					// autoplay={true}
					// autoplaySpeed={5000}
				>
					{!hideBanner &&
						<CountdownCarousel timeLeft={timeLeft} />
					}
					
					{images.map((image, index) => (
						<div key={index} onClick={() => handleImageClick(image.pageName)}>
							<img
								src={image.img}
								alt={`carousel`}
								style={{ objectFit: 'contain', width:'100%', height:'100%', borderRadius: '16px', padding:'8px'}}
							/>
						</div>
					))}
				</Carousel>
		</div>
	);
};

export default My_carousel;
